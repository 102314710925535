import apiHelper from './index';

async function sendMailNotification(email_payload) {
  return await apiHelper.post(
    'notification_management/notify_assigned_users/',
    email_payload,
    false
  );
}

async function sendNotificationEmail(payload) {
  return await apiHelper.post(
    'notification_management/send_bulk_email/',
    payload,
    false
  );
}

export default { sendMailNotification, sendNotificationEmail };
